import "core-js/modules/es.array.push.js";
import BottomBar from '@/components/Bottom.vue';
import router from './router';
export default {
  components: {
    BottomBar: BottomBar
  },
  data: function data() {
    return {
      isShowMenu1: false,
      isShowMenu2: false,
      isShowMenu3: false,
      isShowMenu4: false,
      isShowMenu5: false,
      isShowMenu6: false
    };
  },
  methods: {
    dorpDown1: function dorpDown1(e) {
      this.isShowMenu1 = true;
    },
    dorpUp1: function dorpUp1(e) {
      this.isShowMenu1 = false;
    },
    click1: function click1() {
      router.push('/');
    },
    dorpDown2: function dorpDown2(e) {
      this.isShowMenu2 = true;
    },
    dorpUp2: function dorpUp2(e) {
      this.isShowMenu2 = false;
    },
    click2: function click2() {
      router.push('read');
    },
    dorpDown3: function dorpDown3(e) {
      this.isShowMenu3 = true;
    },
    dorpUp3: function dorpUp3(e) {
      this.isShowMenu3 = false;
    },
    dorpDown4: function dorpDown4(e) {
      this.isShowMenu4 = true;
    },
    dorpUp4: function dorpUp4(e) {
      this.isShowMenu4 = false;
    },
    dorpDown5: function dorpDown5(e) {
      this.isShowMenu5 = true;
    },
    dorpUp5: function dorpUp5(e) {
      this.isShowMenu5 = false;
    },
    dorpDown6: function dorpDown6(e) {
      this.isShowMenu6 = true;
    },
    dorpUp6: function dorpUp6(e) {
      this.isShowMenu6 = false;
    }
  }
};